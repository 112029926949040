<template>
  <no-subcription v-if="!getSubscription"/>
  <div v-else class="page-add-inventory">
    <app-loading v-show="loadingData" />
    <v-container v-if="!loadingData">
      <v-card>
        <v-card-title>
          <p>{{
                  $vuetify.lang.t("$vuetify.menu.sell_user")
              }}</p>
        </v-card-title>
        <v-card-text>
            <v-tabs
                v-model="tab"
                background-color="transparent"
                color="basil"
                grow
            >
                <v-tab
            v-for="item in items"
            :key="item.text"
        >
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.text }}
        </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-card
                        color="basil"
                        flat
                    >
                        <v-card-text>
                          <resume-filter-component @updateFilter="loadData"/>
                            <v-expansion-panels
                                v-model="panel"
                                style="margin: 0"
                                multiple
                            >
                                <v-expansion-panel style="margin: 0">
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-icon>mdi-chart-line</v-icon>
                                            <span
                                                style="text-transform: uppercase;font-weight: bold"
                                            >
                    {{
                                                    $vuetify.lang.t(
                                                        "$vuetify.report.graphics"
                                                    )
                                                }}
                  </span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col
                                                class="py-0"
                                                cols="12"
                                                :md="localSalesByEmployer.length > 0 ? 5 : 12"
                                            >
                                                <v-card
                                                    class="mx-auto"
                                                    tile
                                                >
                                                    <v-list dense>
                                                        <v-subheader>
                                                            {{
                                                                localSalesByEmployer.length >
                                                                0
                                                                    ? $vuetify.lang.t(
                                                                    "$vuetify.report.top5Employers"
                                                                    )
                                                                    : $vuetify.lang.t(
                                                                    "$vuetify.report.noTop5"
                                                                    )
                                                            }}
                                                        </v-subheader>

                                                        <v-subheader>
                                                            <v-slider
                                                                v-if="localSalesByEmployer.length > 0"
                                                                v-model="sliderModel"
                                                                :label="$vuetify.lang.t('$vuetify.actions.show')"
                                                                :thumb-color="'green lighten-1'"
                                                                thumb-label="always"
                                                                :max="max"
                                                                :min="min"
                                                            ></v-slider>
                                                        </v-subheader>
                                                        <v-list-item-group
                                                            color="primary"
                                                        >
                                                            <v-list-item
                                                                v-for="(item,i) in localSalesByEmployer.slice(0,sliderModel)"
                                                                :key="i"
                                                            >
                                                                <v-list-item-icon>
                                                                    <v-chip
                                                                        v-if="item.avatar"
                                                                        dark
                                                                    >
                                                                        {{ item.avatar }}
                                                                    </v-chip>
                                                                </v-list-item-icon>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        {{ item.name }}
                                                                    </v-list-item-title>
                                                                    <v-list-item-subtitle
                                                                        v-text="
                                  `${item.netPrice + ' ' + user.company.currency}`
                                "
                                                                    />
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </v-list-item-group>
                                                    </v-list>
                                                </v-card>
                                            </v-col>
                                            <v-col
                                                v-if="localSalesByEmployer.length > 0 && sliderModel > 0"
                                                class="py-0"
                                                cols="12"
                                                md="7"
                                            >
                                                <highcharts :options="chartOptions" />
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel style="margin: 0">
                                    <v-expansion-panel-header>
                                        <div>
                                            <v-icon>mdi-table-large</v-icon>
                                            <span
                                                style="text-transform: uppercase;font-weight: bold"
                                            >
                    {{
                                                    $vuetify.lang.t(
                                                        "$vuetify.panel.basic"
                                                    )
                                                }}
                  </span>
                                        </div>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>

                                      <product-table-info :local-sales-by-product="localSalesByEmployer" :currency="user.company.currency" :um="false" :expand-table="false"/>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <v-card
                        color="basil"
                        flat
                    >
                        <v-card-text v-if="localSalesByEmployer.length > 0">
                          <sale-summary :products-data="getSalesProductData" :currency="user.company.currency" :first-date="firstDate" :second-date="secondDate"/>
                            <p>El(s) empleado(s) con más venta(s):</p>
                          <employee-sumary :sale-employer="localSalesByEmployer" :currency="user.company.currency"/>
                        </v-card-text>
                        <v-card-text v-else>
                            <p>En el intervalo desde
                                {{ firstDate.toLocaleDateString() + ' ' + firstDate.getHours() + ':' + firstDate.getMinutes() }}
                                hasta
                                {{ secondDate.toLocaleDateString() + ' ' + secondDate.getHours() + ':' + secondDate.getMinutes() }},
                                no se han efectuado ventas</p>
                        </v-card-text>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import AppLoading from '../../../components/core/AppLoading'
import { Chart } from 'highcharts-vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import ResumeFilterComponent from './ResumeFilterComponent'
import SaleSummary from './summary/SaleSummary'
import EmployeeSumary from './summary/EmployeeSumary'
import ProductTableInfo from './ProductTableInfo'

export default {
  name: 'SalesEmployer',
  components: { ProductTableInfo, EmployeeSumary, SaleSummary, ResumeFilterComponent, AppLoading, highcharts: Chart },
  data () {
    return {
      totalSale: 0.00,
      tab: null,
      items: [{
        text: this.$vuetify.lang.t('$vuetify.report.graphics'),
        icon: 'mdi-chart-bar'
      }, {
        text: this.$vuetify.lang.t('$vuetify.menu.resume'),
        icon: 'mdi-file-document'
      }],
      min: 0,
      max: 5,
      sliderModel: 5,
      loadingData: false,
      panel: [0],
      seriesData: [],
      chartOptions: {},
      vBindOption: {
        itemKey: 'no_facture',
        singleExpand: false,
        showExpand: false
      },
      firstDate: new Date(),
      secondDate: new Date(),
      menu: false,
      formRule: this.$rules,
      localShops: [],
      localSalesByEmployer: []
    }
  },
  computed: {
    ...mapState('shop', ['shops', 'isShopLoading']),
    ...mapState('sale', ['salesByEmployer', 'isTableLoading']),
    ...mapGetters('sale', ['getSalesProductData']),
    ...mapGetters('auth', ['user']),
    ...mapState('auth', ['company_subscription']),
    getSubscription () {
      return this.company_subscription ? !this.company_subscription.is_caduced : false
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.cant_sale'),
          value: 'cantTransactions',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.cant_operations'),
          value: 'totalOperations',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.total_cost'),
          value: 'totalCost',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.grossSale'),
          value: 'grossPrice',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.discountsSale'),
          value: 'totalDiscount',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.tax.total_pay_tax'),
          value: 'totalTax',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.report.netPrice'),
          value: 'netPrice',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dashboard.revenue') + '($)',
          value: 'margin',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dashboard.revenue') + '%',
          value: 'margin_percent',
          select_filter: true
        }
      ]
    }
  },
  watch: {
    localSalesByEmployer: function () {
      this.max = this.localSalesByEmployer.length
      this.sliderModel = this.localSalesByEmployer.length >= 5 ? 5 : this.localSalesByEmployer.length
    },
    sliderModel: function () {
      this.loadData()
    }
  },
  methods: {
    ...mapActions('shop', ['getShops']),
    ...mapActions('sale', ['getSaleByEmployer']),
    async changeShop () {
      this.loadingData = true
      if (this.localShops.length === 0) {
        this.localShops.push(this.shops[0])
      } else {
        await this.loadSalesByEmployer()
      }
      this.loadingData = false
    },
    async loadData (firstDate, secondDate, localShop) {
      if (firstDate && secondDate && localShop.length > 0) {
        this.firstDate = firstDate
        this.secondDate = secondDate
        await this.getSaleByEmployer({
          shops: localShop,
          dates: [this.firstDate, this.secondDate],
          online: this.$route.path.split('online').length > 1
        }).then(() => {
          this.localSalesByEmployer = this.salesByEmployer.sort(function (
            a,
            b
          ) {
            if (a.netPrice > b.netPrice) return -1
            if (a.netPrice < b.netPrice) return 1
            return 0
          })
        })
        const categories = []
        const series = {
          grossPrice: [],
          totalDiscount: [],
          netPrice: [],
          totalCost: [],
          totalTax: []
        }
        this.localSalesByEmployer.slice(0, this.sliderModel).forEach(v => {
          categories.push(v.name !== '' ? v.name : this.$vuetify.lang.t('$vuetify.no_defined'))
          series.totalCost.push(v.totalCost)
          series.grossPrice.push(v.grossPrice)
          series.totalDiscount.push(v.totalDiscount)
          series.totalTax.push(v.totalTax)
          series.netPrice.push(v.netPrice)
        })
        this.chartOptions = {
          chart: {
            type: 'column'
          },
          title: {
            text: this.$vuetify.lang.t('$vuetify.report.barGraphics')
          },
          subtitle: {
            text: 'inneli.com'
          },
          xAxis: {
            categories: categories,
            crosshair: true
          },
          yAxis: {
            min: 0,
            title: {
              text: this.user ? this.user.company.currency : ''
            }
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:10px"><b>{point.key}</b></span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} ' + this.user.company.currency +
              '</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0
            }
          },
          series: [
            {
              name: this.$vuetify.lang.t(
                '$vuetify.variants.total_cost'
              ),
              data: series.totalCost
            },
            {
              name: this.$vuetify.lang.t('$vuetify.report.grossSale'),
              data: series.grossPrice
            },
            {
              name: this.$vuetify.lang.t(
                '$vuetify.report.discountsSale'
              ),
              data: series.totalDiscount
            },
            {
              name: this.$vuetify.lang.t(
                '$vuetify.tax.total_pay_tax'
              ),
              data: series.totalTax
            },
            {
              name: this.$vuetify.lang.t('$vuetify.report.netPrice'),
              data: series.netPrice
            }
          ],
          credits: {
            enabled: false
          }
        }
      }
    }
  }
}
</script>

<style scoped></style>
