<template>
  <v-list-item-group
    color="primary"
  >
    <v-list-item
      v-for="(item,i) in saleEmployer"
      :key="i"
    >
      <v-list-item-icon>
        <v-chip
          v-if="item.avatar"
          dark
        >
          {{ item.avatar }}
        </v-chip>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }}
        </v-list-item-title>
        <v-list-item-subtitle v-text="`${parseFloat(item.netPrice + item.totalTax).toFixed(2) + ' ' + currency}`"/>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  name: 'EmployeeSumary',
  props: {
    saleEmployer: {
      type: Array,
      default: function () {
        return []
      }
    },
    currency: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>
